@import url('https://fonts.googleapis.com/css?family=Cardo:400,700|Oswald|Material+Icons');
html{
    height: 100%;
}
body {
  margin: 0; 
  font-family: Arial, Helvetica, sans-serif;  
  background-color:#300A25;
  color:white;
  height: 100%;
}
mainaa {

    height: 90%;
}

#nohost-server {
  width: 100%;
  height: 508px;
}

.divterminal {
}
.title-bar {
 display: none;
}
.topnav {
  overflow: hidden;
  background-color: #300a25;
}
.divterm {
  background-color:#252525;
  float: left;
  width: 120px;  
  border-color:grey;   
  border-width:2px;
  border-style:solid;
  border-bottom:none;
}

.subtitlediv {
  border:none;  
  height: 100%;
}

.topnav .aelement {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 10px 16px;
  text-decoration: none;
  font-size: 17px;
}
.subtitlespan {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 10px 16px;
  padding-top: 12px;
  text-decoration: none;
  font-size: 17px;
}

.divrightside {
 float: right;
 background-color: red;
}
.creditother {
    color: gray;
}
footer {
    background-color: black;
    color: gray;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;    
}
.footerp {
    margin: 4px;
}